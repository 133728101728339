import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static getDisciplineSummaryTemplatesUrl() {
		return `/Admin/Discipline/DisciplineSummaryTemplates`;
	}

	static saveDisciplineSummaryTemplateUrl() {
		return `/Admin/Discipline/SaveDisciplineSummaryTemplate`;
	}

	static deleteDisciplineSummaryTemplateUrl() {
		return `/Admin/Discipline/DeleteDisciplineSummaryTemplate`;
	}

	static savePathwaysConfigUrl() {
		return `/Admin/Discipline/SavePathwaysConfig`;
	}

	static savePathwaysConfigSectionUrl() {
		return `/Admin/Discipline/SavePathwaysConfigSection`;
	}

	static getPathwaysConfigsUrl() {
		return `/Admin/Discipline/GetPathwaysConfigs`;
	}

	static previewPopupUrl() {
		return `/Admin/Discipline/PreviewPathwaysModal`;
	}

	static pathwaysPageUrl(isEnabled, memberId) {
		return isEnabled 
			? '/Admin/PractitionerLearningPlan/List/' + memberId
			: '/Admin/PractitionerLearningPlan/Pathways/' + memberId;
	}

	static legacyListPageUrl(memberId) {
		return '/Admin/PractitionerLearningPlan/SimpleList/' + memberId;
	}

	static pathwaysConfigurationDocumentationUrl() {
		return '/Go/Docs_PathwaysConfiguration';
	}

	static CredentialSummaryTemplatesDocumentationUrl() {
		return '/Go/Docs_CredentialSummaryTemplates';
	}
}