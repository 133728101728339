export function registerNamespace(namespace) {
	const _parts = namespace.split('.');

	//Need to init the first part
	let _base = window[_parts[0]] = window[_parts[0]] || {};

	// Now we'll add the reset
	for (let i = 1; i < _parts.length; i++) {
		_base = _base[_parts[i]] = _base[_parts[i]] || {};
	}
};