import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static getIdentityManagementProvidersUrl() {
		return `/SysAdmin/IdentityManagement/GetProviders`;
	}

	static saveIdentityManagementProviderUrl() {
		return `/SysAdmin/IdentityManagement/SaveProvider`;
	}

	static deleteIdentityManagementProviderUrl() {
		return `/SysAdmin/IdentityManagement/DeleteProvider`;
	}

	static createLocalCertificateUrl() {
		return `/SysAdmin/IdentityManagement/CreateLocalCertificate`;
	}

	static removeLocalCertificateUrl() {
		return `/SysAdmin/IdentityManagement/RemoveLocalCertificate`;
	}

	static getLocalCertificateUrl() {
		return `/SysAdmin/IdentityManagement/GetLocalCertificate`;
	}

	static verifyMetadataUrl() {
		return `/SysAdmin/IdentityManagement/VerifyMetadataUrl`;
	}

	static initiateSsoUrl(provider) {
		if (provider.type === 'Identity') {
			return `/SamlSp/InitiateSingleSignOn/${provider.id}`;
		}
		return `/Saml/InitiateSingleSignOn/${provider.id}`;
	}
}