import moment from 'moment'

export function formatDate(value) {
	if (!value) {
		return '';
	}

	var date = moment(value);

	if (!date.isValid) {
		return '';
	}

	return date.format('L'); // MM/DD/YYYY
};

export function formatDateAndTime(value, nullReplacement) {
	if (!value) {
		return nullReplacement || '';
	}

	var date = moment(value);

	if (!date.isValid()) {
		return nullReplacement || '';
	}

	return date.format('MM/DD/YYYY hh:mm A'); // 01/22/2021 11:22 AM
}