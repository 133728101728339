import queryString from 'query-string';
//https://www.npmjs.com/package/query-string

export default class {
	getMvcArea(areaEnum) {
		return (areaEnum === LB.Enums.AreaEnum.Practitioner)
			? "Learner"
			: areaEnum;
	}

	generateQueryString(object, options) {
		let _options = {
			sort: false,
			skipNull: true,
			skipEmptyString: true
		}

		Object.assign(_options, options);

		// the sort value either has to be a function or false
		// true throws and error. :(
		if (_options.sort === true) {
			delete _options.sort;
		}

		let _queryString = queryString.stringify(object, _options);
		return _queryString ? `?${_queryString}` : '';
	}
}
