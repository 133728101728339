let _minBodyHeight = -1;
let _scrollPosition = 0;
let _originalHeight = 0;

let _initialized = false;

const _getBottomEdgeYPos = function () {
	const _$w = $(window);
	const _bottomEdgeYPos = _$w.scrollTop() + _$w.height();
	return _bottomEdgeYPos;
}

const _setMinHeight = function() {
	const _$b = $('body');
	_$b.css('min-height', _minBodyHeight);
}

export function initializeModule() {

	if (!_initialized) {
		_initialized = true;

		$(window).on('scroll',
			function() {
				const _currentHeight = _getBottomEdgeYPos();

				if (_minBodyHeight > _originalHeight) {
					_minBodyHeight = Math.max(_currentHeight, _originalHeight);
					_setMinHeight();
				}
			});
	}
}

export function saveBodySize() {
	if (!_initialized) {
		console.error(
			'method "saveBodySize" on single-page-scroll-keeper was called but never initialized. Call "initializeModule" first');
		return;
	}

	const _bottomEdgeYPos = _getBottomEdgeYPos();
	_scrollPosition = $(window).scrollTop();
	
	_minBodyHeight = _bottomEdgeYPos;
}

export function applyBodySize() {
	if (!_initialized) {
		console.error(
			'method "applyBodySize" on single-page-scroll-keeper was called but never initialized. Call "initializeModule" first');
		return;
	}
	
	const _originalHeight = $('body').height();

	if (_originalHeight < _minBodyHeight) {
		_setMinHeight();
		$(window).scrollTop(_scrollPosition);
	}

}