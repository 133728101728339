import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static deleteMemberRole() {
		return `/admin/profile/DeleteMemberRole`;
	}
}