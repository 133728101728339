import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static pathways(memberId, currentTenantId, currentArea, returnUnfiltered) {
		let _queryString = new this().generateQueryString({ memberId, currentTenantId, currentArea, returnUnfiltered });
		return `/API/LearningPlan/Pathways${_queryString}`;
	}

	static requirementsSummaries(memberId, learningPlanInstanceIds, currentArea) {
		let _queryString = new this().generateQueryString({ memberId, learningPlanInstanceIds, currentArea });
		return `/API/LearningPlan/GetLearningPlanRequirementsSummaries${_queryString}`;
	}

	static singleLearningPlanInstance(learningPlanInstanceId, currentArea) {
		let _queryString = new this().generateQueryString({ learningPlanInstanceId, currentArea});
		return `/API/LearningPlan/GetSingleLearningPlanInstanceForPathwaysCard${_queryString}`;
	}

	static singleLearningPlanDefinition(memberId, learningPlanId, currentArea) {
		let _queryString = new this().generateQueryString({ memberId, learningPlanId, currentArea});
		return `/API/LearningPlan/GetSingleLearningPlanDefinitionForPathwaysCard${_queryString}`;
	}

	static learningPlanCards(currentArea, learningPlanInstanceId, learningPlanId, memberId, includeIncomplete, includeCompletedSuccessfully, includeCompletedUnsuccessfully) {
		let _queryString = new this().generateQueryString({ currentArea, learningPlanInstanceId, learningPlanId, memberId, includeIncomplete, includeCompletedSuccessfully, includeCompletedUnsuccessfully});
		return `/API/LearningPlan/LearningPlanCards${_queryString}`;
	}

	static credential(currentArea, memberId, credentialId, memberRoleId) {
		let _queryString = new this().generateQueryString({ currentArea, memberId, credentialId, memberRoleId });
		return `/API/LearningPlan/Credential${_queryString}`;
	}

	static addRole(learningPlanId, memberId, roleId) {
		let _queryString = new this().generateQueryString({ learningPlanId, memberId, roleId });
		return `/API/LearningPlan/AddRole${_queryString}`;
	}

	static requirementModel(currentArea, learningPlanInstanceId) {
		let _queryString = new this().generateQueryString({ currentArea });
		return `/API/LearningPlan/RequirementModel/${learningPlanInstanceId}${_queryString}`;
	}

	static viewLearningPlanInstance(currentArea, learningPlanInstanceId) {
		const _area = currentArea === LB.Enums.AreaEnum.Practitioner ? 'Learner' : currentArea;
		const _controller = currentArea === LB.Enums.AreaEnum.Practitioner ? 'LearningPlan' : 'PractitionerLearningPlan';
		return `/${_area}/${_controller}/View/${learningPlanInstanceId}`;
	}

	static beginLearningPlanInstance(currentArea, learningPlanId, memberRoleId) {
		const _area = currentArea === LB.Enums.AreaEnum.Practitioner ? 'Learner' : currentArea;
		const _controller = currentArea === LB.Enums.AreaEnum.Practitioner ? 'LearningPlan' : 'PractitionerLearningPlan';
		return `/${_area}/${_controller}/Select/${learningPlanId}?memberRoleId=${memberRoleId}`;
	}

	static deleteLearningPlanInstance() {
		return `/LearningPlan/DeleteLearningPlanInstance`;
	}
}
