import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static appConfigSetting(scrollTo) {
		let _queryString = new this().generateQueryString({ scrollTo });
		return `/SysAdmin/AppConfig/List${_queryString}`;
	}

	static editAppConfigSetting(editSettingId) {
		let _queryString = new this().generateQueryString({ editSettingId });
		return `/SysAdmin/AppConfig/List${_queryString}`;
	}
}