import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static packets(currentArea, workflowInstanceId, memberId) {
		const _queryString = new this().generateQueryString({ currentArea, workflowInstanceId, memberId });
		return `/API/WorkflowSummaryPacket/GetPackets${_queryString}`;
	}

	static createPacket(currentArea, workflowInstanceId, memberId, workflowStepActionId, fromSysAdmin, forceRegeneration) {
		const _queryString = new this().generateQueryString({ currentArea, workflowInstanceId, memberId, workflowStepActionId, forceRegeneration });
		return `/${fromSysAdmin ? 'SysAdmin' : 'API'}/WorkflowSummaryPacket/CreatePacket${_queryString}`;
	}

	static deletePacket(id) {
		const _queryString = new this().generateQueryString({ id });
		return `/API/WorkflowSummaryPacket/DeletePacket${_queryString}`;
	}

	static checkPacket(id) {
		const _queryString = new this().generateQueryString({ id });
		return `/API/WorkflowSummaryPacket/CheckPacket${_queryString}`;
	}

	static renderPacket(id) {
		const _queryString = new this().generateQueryString({ id });
		return `/API/WorkflowSummaryPacket/RenderPacket${_queryString}`;
	}

}
