import urlHelperBase from './_url-helper-base.js';

export default class extends urlHelperBase {

	constructor() {
		super();
	}

	static getBehaviorContextUrl(workflowId) {
		return `/Admin/WorkflowBehavior/Context?workflowId=${workflowId}`;
	}

}